<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      stockinfo: [],
      devicesearch: "",
      getdeviceslist: "",
      selectdevicestatus: "-1",
      jurisdiction: [],
      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      deviceid: 0,
      loading: true,
      alipaysetting: [],
    };
  },
  mounted() {
    this.title = this.$t("menuitems.sales.paysetting");
    this.items = [
      {
        text: this.$t("menuitems.sales.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.sales.paysetting"),
        active: true,
      },
    ];
    //   this.titleTemplate(this.$t('menuitems.devices.lists'))
    this.jurisdiction = JSON.parse(sessionStorage.getItem("jurisdiction"));
    this.activeapp = JSON.parse(sessionStorage.getItem("apps"));
    this.userinfo = JSON.parse(localStorage.getItem("user"));

    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
  },
  methods: {
    getAlipaySetting() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "getpaysetting",
            type: "alipay",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          console.log(response.data);
          that.alipaysetting = response.data.data;
          that.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getWXpaySetting() {
      var that = this;
      that.loading = true;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-tabs
              pills
              vertical
              nav-class="p-0"
              nav-wrapper-class="col-sm-3"
              content-class="pt-0 px-2 text-muted"
            >
              <b-tab
                :title="$t('menuitems.sales.help')"
                active
                title-item-class="mb-2"
              >
                <b-card-text>
                  {{ $t("text.paysetting.help") }}
                </b-card-text>
              </b-tab>
              <b-tab
                :title="$t('menuitems.sales.alipay')"
                @click="getAlipaySetting"
                title-item-class="mb-2"
              >
                <b-card-text v-loading="loading">
                  
                </b-card-text>
              </b-tab>
              <b-tab
                :title="$t('menuitems.sales.wxpay')"
                @click="getWXpaySetting"
                title-item-class="mb-2"
              >
                <b-card-text v-loading="loading"></b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>